@import "../../../css/colors.scss";

.contact-card {
    border-radius: 5px;
    box-shadow: 0px 15px 40px 0 rgba(0, 0, 0, 0.16);
    padding-bottom: 7vw;
    .contact-card-city-menu {
        & > div {
            min-height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $contactMenuBorderColor;
            background-color: $contactMenuNotActiveColor;
            h5 {
                line-height: 1.5;
            }
            &:not(.active) {
                cursor: pointer;
                h5:after {
                    transition: all ease-in-out 0.2s;
                    background: none repeat scroll 0 0 $contactMenuHBorderBottomColor;
                    content: "";
                    display: block;
                    height: 3px;
                    width: 0;
                }
                h5:hover:after {
                    width: 100%;
                }
            }
            &.active {
                background-color: $mainBackgroundColor;
                border-bottom: none;

                h5 {
                    border-bottom: 3px solid $contactMenuHBorderBottomColor;
                }
            }
        }
    }
    .contact-card-city-content {
        background-color: $mainBackgroundColor;
        div:not(:first-child) {
            p {
                @media (min-width: 1367px) {
                    font-size: 1.3rem;
                }
            }
        }
        padding: 2vw;
        img {
            display: block;
            margin: 2rem auto;
            @media (max-width: 540px) {
                transform: scale(0.75);
            }
        }
    }
}
