.content-navigation-row {
  margin-top: 3rem;
  margin-bottom: 3rem;
  img {
    margin-bottom: 1rem;
    //Images
    &.img-fluid {
      @media (max-width: 960px) {
        transform: scale(0.75);
      }
    }
  }
}
