.fundroid-explanatoy {
    margin-top: 3rem;
    @media (max-width: 840px) {
      #infographics {
        display: none;
      }
      .col-auto {
        display: flex;
        margin-bottom: 3rem;
  
        img {
          margin: auto 2rem auto 6rem;
          @media (max-width: 540px) {
            margin: auto 2rem auto 0;
          } 
        }
        h5 {
          margin: auto 0;
        }
      }
    }
    @media (min-width: 841px) {
      .row {
        justify-content: center;
  
        div {
          text-align: center;
          img:not(#infographics) {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }