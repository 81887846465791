@import "../../../css/colors.scss";

.accordion-row {
  .accordion-question-col {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    &.expanded {
      background-color: #eeeeee;
      span {          
        transform: rotate(45deg);
        transition: transform 0.3s ease-in;
      }
    }
    &:hover {
      background-color: #eeeeee;
    }
    h5 {
      margin: auto 0;
    }
    .plus {
      transition: transform 0.3s ease-out;
      display: inline-block;
      min-width: 46px;
      height: 46px;
      background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), #000;
      background-position: center;
      background-size: 50% 2px, 2px 50%; /*thickness = 2px, length = 50% (25px)*/
      background-repeat: no-repeat;
    }

    .alt {
      background: linear-gradient($secondaryButtonColor, $secondaryButtonColor),
        linear-gradient($secondaryButtonColor, $secondaryButtonColor);
      background-position: center;
      background-size: 50% 2px, 2px 50%; /*thickness = 2px, length = 50% (25px)*/
      background-repeat: no-repeat;
    }
  }
}
