@import "../../css/colors.scss";
#landingPage {
    margin-top: 7rem;
    margin-bottom: -7vw;

    .landingpage-background {
        background-size: cover;
        padding-top: 24vw;

        .reasons-container {
            background-color: $mainBackgroundColor;
            box-shadow: 0px 15px 40px 0 rgba(0, 0, 0, 0.16);
            padding: 3rem 2rem 3rem 5rem;
            border-radius: 5px;
            @media (max-width: 1376px) {
                padding-left: 2rem;
            }

            @media (max-width: 767px) {
                padding-left: 0;
            }

            .row {
                margin-bottom: 1.5rem;

                .col-auto:first-child {
                    padding-right: 0;
                }

                span {
                    font-weight: 500;
                }
            }
        }
    }

    h1 {
        font-size: 3rem;
        font-weight: 600;
    }

    #backgroundOverlayButton {
        width: 200px;
        height: 54px;
        box-shadow: 0px 15px 40px 0 rgba(0, 0, 0, 0.16);
        border-radius: 27px;
        font-size: 14px;
    }

    #landingPageOrangeWaveRow {
        padding: 10vw 0;
        margin-top: -4rem;
        position: relative;
        background-repeat: no-repeat;
        background-size: 100% 20vw;
    }
}
#landingpagePhone {
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 65%;
  height: 60vw;
  min-height: 390px;
  background-position: right;
  margin: -20vw -15px -27rem auto;
  @media (max-width: 996px) {
    margin: 0vw -15px -55vw auto;
  }
  @media (max-width: 540px) {
    margin: -5vw -15px -93vw auto;
  }
  position: relative;
  z-index: 10;
}

#landingpageBlueWaveRow {
  padding: 9rem 0 4rem 0;
}
