//Body
$mainBackgroundColor: #ffffff;

//ul
$bulletListColor:#edcf8e;

//Buttons
$primaryButtonColor:#f29782;
$secondaryButtonColor:#8ec4cb;

//Colors
$purpleColor:#ba7ba1;
$goldColor:#d5b266;

//ContactMenu
$contactMenuBorderColor:#e9eef1;
$contactMenuHBorderBottomColor:#b07196;
$contactMenuNotActiveColor: #fefbfd;
