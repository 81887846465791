@import "../../../css/colors.scss";

.article-body-row {
  & > div{
    overflow: hidden;
  }
  .article-body-background-row {
    height: 34vw;
    background-repeat: no-repeat;
    border-top: 4px solid $mainBackgroundColor;
    border-bottom: 4px solid $mainBackgroundColor;
    background-size: 100%;

    .overlay::before {
      content: "";
      background-color: $mainBackgroundColor;
      height: 4vw;
      width: 110%;
      top: -4px;
      left: -5%;
      position: absolute;
      border-radius: 0 0 90% 90%;
    }
    .overlay::after {
      content: "";
      background-color: $mainBackgroundColor;
      height: 4vw;
      width: 110%;
      top: 32vw;
      left: -5%;
      position: absolute;
      border-radius: 90% 90% 0 0;
    }

  }
  .article-body-content-row {

    .article-body-div {
      @media (min-width: 1200px) {
        margin-left: 16.666667%;
        margin-right: 16.666667%;
      }
      padding: 15px;
      img {
        float: right;
        max-width: 40%;
        height: auto;
      }
      .article-body-text-div {
        margin-top: 230px;
        @media (max-width: 996px) {
          margin-top: 170px;
        }
        @media (max-width: 540px) {
          margin-top: 80px;
        }
      }
    }
  }
}
