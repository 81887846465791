@import "../../css/colors.scss";
#aboutPage {
  margin-top: 6rem;
  & > .row:not(:first-child):not(:last-child) {
    margin-bottom: 2rem;
  }
  #aboutPhoneCol {
    @media (max-width: 1024px) {
      margin-top: -5rem;
    }
  }
  #aboutPageBlueWaveRow {
    margin-top: 6rem;
    background-size: 100% 32vw;
    background-repeat: no-repeat;
    background-position: bottom;

    @media (max-width: 540px) {
      background-size: 150%;
      
    }
    .hp-text-col:first-child {
      margin-top: 3rem;
      margin-bottom: 39vw;
      @media (min-width: 769px) {
        margin-bottom: 20vw;
      }
    }
  }
}
