@import '../../../css/colors.scss';

.hp-text-col {
  h4 {
    margin-bottom: 2rem;
  }
  button{
    margin-top: 2rem;
  }
  .cookie-type-text{    
    span{
      color: $goldColor
    }
  }
}
