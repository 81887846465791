#costsPage {
    & > .row:not(:first-child):not(:last-child) {
        margin-bottom: 2rem;
    }
    #costsPageCosts,
    #costsExample {
        .col-12 {
            display: flex;
            justify-content: space-between;
            @media (max-width: 991px) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
    #costsPageCosts {
        .row {
            padding: 15px 0;
        }
    }
    #costsExample {
        .row:not(:first-child) {
            margin-bottom: 0.5rem;
        }
    }
    @media (max-width: 991px) {
        .hp-text-col {
            padding-left: 20px;
            padding-right: 20px;
        }
        #costsPageCosts {
            .highlight-row {
                margin-left: -10px;
                margin-right: -10px;
                .col-12 {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
}
