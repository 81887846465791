@import "../../css/colors.scss";

#historicalReturnPage {
    .return-card {
        box-shadow: 0px 15px 40px 0 rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        margin-bottom: 2rem;
        & > div {
            padding: 2rem 2rem 0 2rem;
            h1 {
                text-align: center;
                font-weight: 400;
                @media (min-width: 996px) {
                    font-size: 60px;
                }
            }
            h6 {
                @media (max-width: 1454px) {
                    min-height: 2.4rem;
                }
            }
        }
        img {
            border-radius: 5px;
            max-width: 100%;
        }
    }

    .chart-legend-container {
        margin-top: 3rem;
        padding: 10px 30px;
        span {
            vertical-align: middle;
            margin: 0 5px;
        }
        .dot {
            @media (max-width: 540px) {
                transform: scale(0.75);
            }

            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: $secondaryButtonColor;
            display: inline-block;
            &:first-child {
                background-color: $primaryButtonColor;
            }
        }
    }
    .chart-em-container {
        padding: 10px 0px 0px 32px;
        font-size: 0.8rem;
    }
    canvas {
        height: 35vw !important;
    }
}
