.performance-text-row {
  @media (min-width: 992px) {
    .col-xl-4{
      max-width: 28.333337%;
    }
  }
  padding: 15px 0;
  justify-content: left !important;
  margin-left: -12px;
  margin-right: -12px;
  .performance-text-header-col {
    margin: auto 0;
    vertical-align: middle;
  }
  .third-col-text {
    text-align: center;    
  }
  @media (max-width: 992px) {
    h4 {
      text-align: left;
    }
    .highlight-text-header-col {
      padding-left: 12px;
    }
  }
}
