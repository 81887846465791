.performance-row {
  .col-6 {
    margin: auto 0 auto 0;
  }
  @media (min-width: 1200px) {
    .offset-xl-1 {
      margin-left: 6.333337%;
    }
    .col-xl-3 {
      max-width: 28.333337%;
    }
    .col-xl-6 {
      max-width: 44%;
    }
    .col-xl-7 {
      max-width: 54.333333%;
    }
  }
  @media (min-width: 768px) {
    .offset-md-1 {
      margin-left: 6.333337%;
    }
  }
}
