@import "../../../css/colors.scss";

#appFooter {
  margin-top: 7vw;
  padding-top: 4rem;
  padding-bottom: 3rem;
  color: $mainBackgroundColor;
  background-color: $primaryButtonColor;
  .footer-container-row {
    justify-content: space-between;
    .col-6 {
      margin-bottom: 1rem;
    }
    hr {
      margin-right: 5rem;
    }
    a {
      color: inherit;
      font-size: 0.9rem;
    }
    .footer-link-column {
      div {
        margin-bottom: 1rem;
      }
    }
    .footer-social-media-col {
      a {
        margin-right: 1rem;
        &:active {
          img {
            transform: scale(0.95);
          }
        }
      }
      @media (max-width: 1350px) {
        img {
          width: 45px;
          height: 45px;
        }
      }
      @media (max-width: 550px) {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
