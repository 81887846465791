@import "./colors.scss";

body,
html {
  font-family: "Poppins", sans-serif !important;
  @media (max-width: 540px) {
    font-size: 0.8rem;
    h1 {
      font-size: 2rem !important;
    }

    h5 {
      font-size: 1rem;
    }
  }
}

//Modifiers
.fake-link {
  color: $goldColor;
  text-decoration: underline;
}
.highlight-row {
  box-shadow: 0px 15px 40px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
@media (max-width: 992px) {
  .content-navigation-row .col-12 {
    text-align: center !important;
  }
}
@media (max-width: 540px) {
  .--justify-content-sm-center {
    justify-content: center !important;
  }
}
.--negative-margin-bottom-5 {
  margin-bottom: -5vw;
}
.--negative-margin-top-7 {
  margin-top: -7vw;
}
.--margin-bottom-1 {
  margin-bottom: 1vw;
}
.--margin-bottom-2 {
  margin-bottom: 2rem;
}
.--margin-bottom-3 {
  margin-bottom: 3vw;
}
.--margin-bottom-5 {
  margin-bottom: 5vw;
}
.--margin-bottom-10 {
  margin-bottom: 10vw;
}

.--margin-top-bottom-7 {
  margin-top: 7vw;
  margin-bottom: 7vw;
}
.--text-color-primary {
  color: $primaryButtonColor;
}
.--text-color-gold {
  color: $goldColor;
}
.--align-content-center {
  margin-top: auto;
  margin-bottom: auto;
}
//Google map zoom icons fix
.gm-control-active {
  & > img {
    left: 16% !important;
  }
}

//Buttons

button:not(#navToggler) {
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 21.5px;
  font-size: 12px;
  padding: 10px 15px;
  min-width: 127px;
  border: 1px solid transparent;  
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
  &.primary {
    background-color: $primaryButtonColor;
    color: $mainBackgroundColor;
    border-color: $primaryButtonColor;
  }
  &.outline-primary {
    color: $primaryButtonColor;
    border-color: $primaryButtonColor;
  }
  &.secondary {
    color: $mainBackgroundColor;
    border-color: $secondaryButtonColor;
    background-color: $secondaryButtonColor;
  }
}

a{
  button{
    background-color: $mainBackgroundColor;
  }
}
