@import "../../../css/colors.scss";

.list-ul {
    list-style: none;
    li:before {
        content: "\2022";
        vertical-align: middle;
        font-size: 1.5rem;
        color: $bulletListColor;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
}
