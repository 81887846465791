@import "../../css/colors.scss";

#personalInformationPage {
    & > .row:not(:first-child):not(:last-child) {
        margin-bottom: 2rem;
    }
    .hp-text-col {
        h4 {
            margin-bottom: 0.5rem;
        }
    }
    a {
        color: $goldColor;
    }
}
