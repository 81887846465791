.article-header-row {
  margin-top: 6rem;
  background-size: 100% 32vw;
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 32vw;
  @media (max-width: 540px) {
    background-size: 150%;
    min-height: 40vw;
  }
  & > div {
    margin: auto;
  }

  .article-header-icon-container {
    margin: auto;
    img {
      margin-bottom: -4rem;
      &.img-fluid {
        @media (max-width: 960px) {
          transform: scale(0.75);
        }
      }
      &:last-child {
        position: absolute;
        top: 32px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
