@import "../../../css/colors.scss";

header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    a {
        font-weight: 500;
    }

    nav {
        padding: 1rem !important;
        background-color: $mainBackgroundColor;
        @media (min-width: 1176px) {
            padding-left: 4rem !important;
            padding-right: 4rem !important;
        }
        div {
            ul.navbar-nav {
                li.nav-item {
                    white-space: nowrap;
                    margin: auto 1rem auto 0;

                    a.nav-link {
                        &::after {
                            transition: all ease-in-out 0.2s;
                            background: none repeat scroll 0 0 $primaryButtonColor;
                            content: "";
                            display: block;
                            height: 3px;
                            width: 0;
                        }

                        &:hover:after {
                            width: 100%;
                        }

                        &.active-link:after {
                            background: none repeat scroll 0 0 $primaryButtonColor;
                            content: "";
                            display: block;
                            height: 3px;
                            width: 100%;
                        }
                    }
                }

                @media (min-width: 1030px) {
                    .container-fluid {
                        max-width: 80%;
                    }
                }

                @media (max-width: 996px) {
                    button {
                        margin-bottom: 0.7rem;
                    }
                }
            }
        }
    }
}
